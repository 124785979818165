import React from "react";

const Loading = () => {
  return (
    <div id="loading-screen" className="overflow-hidden">
      <img className="loading" src="/assets/loading.gif" alt="Loading..." />
    </div>
  );
};

export default Loading;
